import { GET_TENANT_INFO, TENANT_INFO_ERROR } from "./types";
import axios from "axios";
import { setAlert } from "./alert";
import customConfig from "../config";
import env from "../env";
import loginLogo from "../../src/assets/black_logo.png";
import setTenantId from "../utils/setTenantId";

export const getTenantInfo = (dispatch) => () => {
  return new Promise((resolve, reject) => {
    const host = window.location.host;

    let tenantId = "";
    let static_tenant_response = {
      tenantId: "panasonic",
      tenantExternal: "miraie",
      tenantDisplayName: "MirAIe",
      tenantLogoUrl: "https://www.panasonic.com/content/panasonic/in/en/_jcr_content/masthead/primaryLogo.img.png/1605870828565.png",
      selectedTheme: "rectangular",
      primaryColor: "grey",
      secondaryColor: "orange",
      tenantFavicon: "https://web.miraie.in/favicon.ico",
    };

    if (host.includes('smarthomeplus')) {
      tenantId = 'flipkart'
    }
    else if (host.includes('appliancehub')) {
      tenantId = 'amazon'
    }
    else {
      tenantId = 'panasonic'
    }

    const response = axios.get(
      `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/tenantManagement/web/tenant/${tenantId}`
    );

    response
      .then((res) => {
        let data = res.data;
        dispatch({
          type: GET_TENANT_INFO,
          payload: data,
        });
        setTenantId(data.tenantId);
        resolve(data);
      })
      .catch((err) => {
        if (host.includes('localhost') || host.includes("127.0.0.1")) {
          const data = static_tenant_response;
          dispatch({
            type: GET_TENANT_INFO,
            payload: data,
          });
          setTenantId(data.tenantId);
          resolve(data);
          return
        }

        if (!err.response) {
          dispatch(setAlert(customConfig.serverDownMsg, "danger"));
        } else if (err.response.status === 429) {
          dispatch(setAlert(err.response.data.message, "danger"));
        } else if (err.response.status === 500) {
          //added for invalid captcha
          dispatch(setAlert(err.response.data.message, "danger"));
        } else {
          dispatch(setAlert(customConfig.authMsg, "danger"));
        }
        dispatch({
          type: TENANT_INFO_ERROR,
        });
        reject(err);
      })
  });
};
